import logo from './logo.png';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                <h2>[BANK] Pool</h2>
                <p className="App-header-subtext">A secure and scalable ADA stake pool founded with a mission to help the bankless.</p>
                <iframe width="500" height="360" frameBorder="0"
                        src="https://js.adapools.org/widget-dark.html?pool=0deabfea77fa4fffe204e09b10b36ef42cd807da33fb86e8735545f9">
                    <a href="https://adapools.org/pool/0deabfea77fa4fffe204e09b10b36ef42cd807da33fb86e8735545f9">Detail</a>
                </iframe>
            </header>
        </div>
    );
}

export default App;
